import { ExternalLink, Span, Text } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";
import { Box } from "@mui/material";
import { Li } from "@src/appV2/lib/Lists/index";
import { Ol } from "@src/appV2/lib/Lists/Ol/index";
import { NfcLocationsDescription } from "@src/appV2/Shifts/NfcHashes/components/NfcLocationsDescription";
import { ReadingFailureType } from "@src/appV2/Shifts/NfcHashes/NfcDialog/types";
import type { NfcTagRequest } from "@src/appV2/Shifts/Shift/types";

export interface ScanTimeoutContentProps {
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];
  failureType: ReadingFailureType;
}

export function ScanTimeoutContent(props: ScanTimeoutContentProps): JSX.Element {
  const { facilityName, nfcTagRequests, failureType } = props;
  return (
    <>
      <Text bold variant="h2">
        ⚠️ Error: tag not detected
      </Text>
      <Text variant="h4">We couldn’t detect a signal from an NFC tag.</Text>

      <NfcLocationsDescription
        nfcTagRequests={nfcTagRequests}
        facilityName={facilityName}
        textVariant="h4"
        listTextVariant="body1"
        isLocationBold={failureType === ReadingFailureType.SCAN_TIMEOUT}
      />

      <Box>
        <Text bold variant="h4">
          Tips to successfully scan NFC:
        </Text>
        <Ol mb={0}>
          <Li>
            If you have poor reception,{" "}
            <strong>
              try connecting to <Span whiteSpace="nowrap">Wi-Fi</Span>
            </strong>
            .
          </Li>
          <Li>
            It may help to <strong>take off your phone case</strong>.
          </Li>
          <Li>
            Hold the{" "}
            <strong>
              {isPlatform("android") ? "back center" : "top"} of your phone within 1 centimeter
            </strong>{" "}
            of the rectangle on the NFC time clock poster.{" "}
          </Li>
        </Ol>
      </Box>

      <Text variant="h4">
        If you still need help, please{" "}
        <ExternalLink to="https://youtu.be/WuXARGIxdGg" fontWeight="bold">
          <strong>watch this quick NFC tutorial</strong>
        </ExternalLink>
        .
      </Text>
    </>
  );
}

import { z } from "zod";

export enum NfcHashValidationAction {
  CLOCK_IN = "clock-in",
  CLOCK_OUT = "clock-out",
  BREAK_START = "break-start",
  BREAK_END = "break-end",
  SKIP_BREAK = "skip-break",
}

export const postNfcHashValidationRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      action: z.nativeEnum(NfcHashValidationAction),
      hash: z.string(),
    }),
    relationships: z.object({
      shift: z.object({
        data: z.object({
          id: z.string(),
          type: z.literal("shift"),
        }),
      }),
    }),
    type: z.literal("nfc-hash-validation"),
  }),
});

export const postNfcHashValidationResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("nfc-hash-validation"),
    attributes: z.object({
      action: z.nativeEnum(NfcHashValidationAction),
      createdAt: z.string(),
    }),
    relationships: z.object({
      shift: z.object({
        data: z.object({
          id: z.string(),
          type: z.literal("shift"),
        }),
      }),
      worker: z.object({
        data: z.object({
          id: z.string(),
          type: z.literal("agent-profile"),
        }),
      }),
    }),
  }),
});

export enum NfcHashValidationResponseErrorType {
  INVALID_HASH = "invalid-hash",
  INCORRECT_HASH = "incorrect-hash",
  INACTIVE_HASH = "inactive-hash",
  OTHER_FAILURE = "other-failure",
}

export const postNfcHashValidationErrorResponseSchema = z.object({
  errors: z.array(
    z.object({
      code: z.nativeEnum(NfcHashValidationResponseErrorType),
      status: z.string().optional(),
      title: z.string().optional(),
    })
  ),
});

export type PostNfcHashValidationResponse = z.infer<typeof postNfcHashValidationResponseSchema>;
export type PostNfcHashValidationErrorResponse = z.infer<
  typeof postNfcHashValidationErrorResponseSchema
>;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

export function AcceptNonIpAlert(props: {
  isOpen: boolean;
  onCancel: () => void;
  onAcceptNonIp: () => void;
}) {
  const { isOpen, onCancel, onAcceptNonIp } = props;

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onCancel}
    >
      <DialogTitle id="alert-dialog-title">Accept non-InstantPay?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By proceeding, your shift will be converted to non-InstantPay and you will need to submit
          a paper timesheet in order to get paid.
          <br />
          <br />
          Please note that it can take up to two business days to process payments from a paper
          timesheet.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack spacing={1} width="100%">
          <Button
            fullWidth
            variant="outlined"
            color="error"
            sx={{ color: "error.main" }}
            onClick={onAcceptNonIp}
          >
            Accept non-InstantPay
          </Button>
          <Button fullWidth variant="contained" onClick={onCancel}>
            Go back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

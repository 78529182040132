import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { NfcLocationsDescription } from "@src/appV2/Shifts/NfcHashes/components/NfcLocationsDescription";
import { ReadingFailureType } from "@src/appV2/Shifts/NfcHashes/NfcDialog/types";
import type { NfcTagRequest } from "@src/appV2/Shifts/Shift/types";

export interface InvalidHashContentProps {
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];
  failureType: ReadingFailureType;
}

export function InvalidHashContent(props: InvalidHashContentProps): JSX.Element {
  const { facilityName, nfcTagRequests, failureType } = props;
  return (
    <>
      <Text bold variant="h2">
        ⚠️ Error: incorrect poster
      </Text>
      <Text variant="h4">
        It looks like you scanned a Clipboard Health NFC time clock poster, but it’s not the right
        one for {facilityName}.
      </Text>

      <Text variant="h4">
        This usually happens when a facility has multiple units, each with its own NFC time clock
        poster. You are probably scanning an NFC poster for the wrong unit.
      </Text>

      <NfcLocationsDescription
        nfcTagRequests={nfcTagRequests}
        facilityName={facilityName}
        textVariant="h4"
        listTextVariant="body1"
        isLocationBold={failureType === ReadingFailureType.SCAN_TIMEOUT}
      />

      <Text variant="h4">
        If you still need help, please{" "}
        <ExternalLink to="https://youtu.be/WuXARGIxdGg" fontWeight="bold">
          <strong>watch this quick NFC tutorial</strong>
        </ExternalLink>
        .
      </Text>
    </>
  );
}

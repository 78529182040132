import { Text } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { SlideUpTransition } from "@src/appV2/lib";

import { type NfcTagRequest } from "../../../Shift/types";
import { ReadingFailureType } from "../types";
import { InvalidHashContent } from "./ScanFailedContent/InvalidHashContent";
import { NfcDisabledContent } from "./ScanFailedContent/NfcDisabledContent";
import { ScanTimeoutContent } from "./ScanFailedContent/ScanTimeoutContent";
import { UnknownErrorContent } from "./ScanFailedContent/UnknownErrorContent";

export interface ScanFailedStageProps {
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];
  attemptNumber: number;
  failureType?: ReadingFailureType;
  errorCode?: string;
  onBack: () => void;
  onSkipVerification: () => void;
}

export function ScanFailedStage(props: ScanFailedStageProps) {
  const {
    facilityName,
    nfcTagRequests,
    attemptNumber,
    failureType = ReadingFailureType.OTHER,
    errorCode,
    onBack,
    onSkipVerification,
  } = props;

  function getScanFailedContent(props: {
    facilityName: string;
    nfcTagRequests: NfcTagRequest[];
    failureType: ReadingFailureType;
  }) {
    const { failureType } = props;

    switch (failureType) {
      case ReadingFailureType.INVALID_HASH: {
        return <InvalidHashContent {...props} />;
      }

      case ReadingFailureType.NFC_DISABLED: {
        return <NfcDisabledContent />;
      }

      case ReadingFailureType.SCAN_TIMEOUT: {
        return <ScanTimeoutContent {...props} />;
      }

      default: {
        return <UnknownErrorContent {...props} />;
      }
    }
  }

  return (
    <SlideUpTransition in>
      <Stack minHeight="100%">
        <Stack flexGrow="1" spacing={3}>
          {getScanFailedContent({ failureType, facilityName, nfcTagRequests })}

          <Text variant="caption" fontStyle="italic">
            Error code: {errorCode ?? failureType}
          </Text>
        </Stack>

        <Stack spacing={2} mt={2}>
          <Button variant="contained" onClick={onBack}>
            Try again
          </Button>
          {attemptNumber > 1 && (
            <Button
              color="error"
              sx={{ color: "red" }}
              variant="outlined"
              onClick={onSkipVerification}
            >
              Skip NFC verification
            </Button>
          )}
        </Stack>
      </Stack>
    </SlideUpTransition>
  );
}

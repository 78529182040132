export enum ReadingFailureType {
  // Covers Scan Timeouts (no tag detected)
  SCAN_TIMEOUT = "SCAN_TIMEOUT",
  // Covers situations of invalid, incorrect, inactive hashes
  INVALID_HASH = "INVALID_HASH",
  // Covers situation when NFC was disabled on the device
  NFC_DISABLED = "NFC_DISABLED",
  // Covers all other cases
  OTHER = "OTHER",
}

import { Text } from "@clipboard-health/ui-react";

export function NfcDisabledContent(): JSX.Element {
  return (
    <>
      <Text bold variant="h2">
        ⚠️ Error: NFC is disabled on your device
      </Text>
      <Text variant="h4">It looks like NFC scanning is disabled on your Android device.</Text>

      <Text variant="h4">
        You must enable NFC in order to use Clipboard Health NFC time clocks.
      </Text>

      <Text variant="h4">
        To enable NFC on your device, please go to device settings and search for “NFC”. You should
        see an option to turn on NFC.
      </Text>

      <Text variant="h4">
        Once you have NFC enabled, please tap “Try again” below to reattempt scanning the Clipboard
        Health NFC time clock poster.
      </Text>
    </>
  );
}

import { NfcHashValidationAction } from "../api/types";

export function clockActionToReadableString(clockAction: NfcHashValidationAction) {
  return {
    [NfcHashValidationAction.CLOCK_IN]: "clock in",
    [NfcHashValidationAction.CLOCK_OUT]: "clock out",
    [NfcHashValidationAction.BREAK_START]: "start your break",
    [NfcHashValidationAction.BREAK_END]: "end your break",
    [NfcHashValidationAction.SKIP_BREAK]: "skip your break",
  }[clockAction];
}

export function clockActionToReadableVerb(clockAction: NfcHashValidationAction) {
  return {
    [NfcHashValidationAction.CLOCK_IN]: "clock in",
    [NfcHashValidationAction.CLOCK_OUT]: "clock out",
    [NfcHashValidationAction.BREAK_START]: "break start",
    [NfcHashValidationAction.BREAK_END]: "break end",
    [NfcHashValidationAction.SKIP_BREAK]: "break skip",
  }[clockAction];
}

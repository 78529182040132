import { Text } from "@clipboard-health/ui-react";
import { isPlatform } from "@ionic/core";
import { Box, Stack } from "@mui/material";

import { type NfcTagRequest } from "../../Shift/types";
import { NfcLocationsDescription } from "../components/NfcLocationsDescription";

interface ScanInstructionDetailsProps {
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];
}

export function ScanInstructionDetails({
  facilityName,
  nfcTagRequests,
}: ScanInstructionDetailsProps) {
  return (
    <Stack spacing={2}>
      <Text variant="h4">
        NFC Time Clocks are white posters with the Clipboard logo on the top-right.
      </Text>
      <NfcLocationsDescription
        nfcTagRequests={nfcTagRequests}
        facilityName={facilityName}
        textVariant="h4"
        listTextVariant="body1"
      />
      <Text variant="h4">
        Hold the <strong>{isPlatform("android") ? "back center" : "top"}</strong> of your phone over
        the rectangle on the poster (like contactless payment).
      </Text>

      <Box display="flex" justifyContent="center" alignItems="center">
        <img src="/assets/gifs/nfcTap.gif" alt="Scan NCF tag" width={300} height="auto" />
      </Box>
    </Stack>
  );
}

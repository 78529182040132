import { type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib/Dialogs";
import { USER_EVENTS } from "@src/constants";
import { useRef, useState } from "react";

import { type NfcTagRequest } from "../../Shift/types";
import { type NfcHashValidationAction } from "../api/types";
import { type CommonNfcScanEventBase, sendNfcScanEvent } from "../events/nfcEvents";
import { AcceptNonIpAlert } from "./AlertDialogs/AcceptNonIpAlert";
import { ScanFailedStage } from "./Stages/ScanFailed";
import { ScanInstructionsStage } from "./Stages/ScanInstructions";
import { ScanPendingStage } from "./Stages/ScanPending";
import { type ReadingFailureType } from "./types";

interface NfcScanDialogProps {
  modalState: UseModalState;
  shiftId: string;
  metaProps: {
    workerId: string;
    workplaceId: string;
    workplaceName: string;
  };
  nfcTagRequests: NfcTagRequest[];
  clockAction: NfcHashValidationAction;

  // Final states that will close the dialog
  onClose: () => void;
  onSuccess: () => void;
  onConvertToNonInstantPay: () => void;
}

type ScanningStage = "instructions" | "scanning" | "failure";
export function NfcScanDialog(props: NfcScanDialogProps) {
  const {
    modalState,
    shiftId,
    metaProps: { workplaceName, workplaceId, workerId },
    nfcTagRequests,
    clockAction,
    onClose,
    onSuccess,
    onConvertToNonInstantPay,
  } = props;

  const scanStartTimeRef = useRef(Date.now());
  const [currentAttemptNumber, setCurrentAttemptNumber] = useState(0);
  const [currentScanStage, setCurrentScanStage] = useState<ScanningStage>("instructions");
  const [readingFailureError, setReadingFailureError] = useState<{
    type: ReadingFailureType;
    code: string | undefined;
  }>();
  const [isConvertNonIpDialogOpen, setIsConvertNonIpDialogOpen] = useState(false);

  const resetAfterClose = () => {
    setCurrentAttemptNumber(0);
    setCurrentScanStage("instructions");
    setReadingFailureError(undefined);
  };

  const startScanInits = (attemptNumber: number) => {
    setCurrentAttemptNumber(attemptNumber);
    scanStartTimeRef.current = Date.now();
  };

  const getScanDurationInSeconds = (): number => {
    return (Date.now() - scanStartTimeRef.current) / 1000;
  };

  const getCommonScanEventProps = (attemptNumber: number): CommonNfcScanEventBase => ({
    shiftId,
    agentId: workerId,
    facilityId: workplaceId,
    shiftClockAction: clockAction,
    attemptNumber,
  });

  return (
    <FullScreenDialog modalState={modalState}>
      <DialogContent
        sx={{ paddingTop: 4, paddingBottom: "max(env(safe-area-inset-bottom), 24px)" }}
      >
        <AcceptNonIpAlert
          isOpen={isConvertNonIpDialogOpen}
          onCancel={() => {
            setIsConvertNonIpDialogOpen(false);
          }}
          onAcceptNonIp={() => {
            setIsConvertNonIpDialogOpen(false);
            onConvertToNonInstantPay();
            resetAfterClose();
          }}
        />

        {currentScanStage === "instructions" && (
          <ScanInstructionsStage
            facilityName={workplaceName}
            nfcTagRequests={nfcTagRequests}
            clockAction={clockAction}
            onBack={() => {
              onClose();
              resetAfterClose();
            }}
            onStart={() => {
              const newAttemptNumber = currentAttemptNumber + 1;
              startScanInits(newAttemptNumber);
              setCurrentScanStage("scanning");

              sendNfcScanEvent({
                type: USER_EVENTS.NFC_SCAN_START,
                data: {
                  ...getCommonScanEventProps(newAttemptNumber),
                },
              });
            }}
          />
        )}

        {currentScanStage === "scanning" && (
          <ScanPendingStage
            clockAction={clockAction}
            shiftId={shiftId}
            facilityName={workplaceName}
            nfcTagRequests={nfcTagRequests}
            onCancel={() => {
              sendNfcScanEvent({
                type: USER_EVENTS.NFC_SCAN_CANCELLED,
                data: {
                  ...getCommonScanEventProps(currentAttemptNumber),
                  scanDuration: getScanDurationInSeconds(),
                },
              });

              onClose();
              resetAfterClose();
            }}
            onFail={(failureType: ReadingFailureType, errorCode?: string) => {
              setReadingFailureError({
                type: failureType,
                code: errorCode,
              });
              setCurrentScanStage("failure");

              sendNfcScanEvent({
                type: USER_EVENTS.NFC_SCAN_FAILED,
                data: {
                  ...getCommonScanEventProps(currentAttemptNumber),
                  scanDuration: getScanDurationInSeconds(),
                  failureReason: failureType,
                  errorCode,
                },
              });
            }}
            onSuccess={() => {
              onSuccess();
              resetAfterClose();

              sendNfcScanEvent({
                type: USER_EVENTS.NFC_SCAN_SUCCEEDED,
                data: {
                  ...getCommonScanEventProps(currentAttemptNumber),
                  scanDuration: getScanDurationInSeconds(),
                },
              });
            }}
          />
        )}

        {currentScanStage === "failure" && (
          <ScanFailedStage
            facilityName={workplaceName}
            nfcTagRequests={nfcTagRequests}
            failureType={readingFailureError?.type}
            errorCode={readingFailureError?.code}
            attemptNumber={currentAttemptNumber}
            onBack={() => {
              setCurrentScanStage("instructions");
            }}
            onSkipVerification={() => {
              setIsConvertNonIpDialogOpen(true);
            }}
          />
        )}
      </DialogContent>
    </FullScreenDialog>
  );
}
